import Header from "../components/Header";
import arch16 from "../app/assets/img/balloon-Backdrop-16-square.jpg";
import owl from "../app/assets/img/kev-owl2-sm.jpg";
import mermaid from "../app/assets/img/centerpiece-meramid.jpg";
import halloween from "../app/assets/img/balloon-columns-Halloween.jpg";
import rainbowColumns from "../app/assets/img/Column-rainbow4-square.jpg";
import flamingo from "../app/assets/img/flamingoColumns.jpg";
import columns from "../app/assets/img/columns50.jpg";
import superheroArch from "../app/assets/img/arch-superhero-square.jpg";
import garland from "../app/assets/img/balloon-Garlands-ceiling-square.jpg";
import easter from "../app/assets/img/easter.jpg";
import santa from "../app/assets/img/christmas-display.jpg";
import skier from "../app/assets/img/centerpiece-skier.jpg";
import halloweenkev from "../app/assets/img/columns-halloween-kev-sq.jpg";
import swan from "../app/assets/img/centerpiece-swan.jpg";
import july4 from "../app/assets/img/garland-4thjuly.jpg";
import kev from "../app/assets/img/kev-twisting1.jpg";
import brit from "../app/assets/img/britofficial.png";
import "./pagescss/gallerypage.css";

const Gallerypage = () => {
  return (
    <>
      <Header
        headline="About Us"
        headshotDisplay="none"

        tagline="Who is behind Kevin's Balloons?"
        display="none"
      />
      <div className="aboutus-section">
        <h1 className="aboutus-header">
          Kevin's Balloons is a Bay Area family enterprise with a global appeal!
        </h1>
        <p className="aboutus-text">
          Kevin's Balloons is run by Kevin & Brit Camice, born and raised here{" "}
          <img
            alt=""
            className="service-img service-img-float-left"
            src={kev}
          />
          in California. They got their start in Los Angeles, when Brit founded
          her own childrens' entertainment company at 18. After a{" "}
          <img
            alt=""
            className="service-img service-img-float-right"
            src={brit}
          />
          meteoric rise, they soon were performing at star-studded events and
          making balloons for celebrities, like Jason Momoa, Hugh Hefner, and
          Jennifer Garner. After several years of success, they decided to see
          the world, and now Brit is a renowned international event planner,
          running some of the the largest Digital Nomad events in the world, but
          she still finds time to run Kevin's Balloons behind the scenes. Kevin
          continued honing his skills as a balloon artist and today is
          considered one of the best in the world!
        </p>
  
        <div className="gallery-row">
          <img
            className="gallery-img"
            src={owl}
            alt="Giant balloon owl balloon animal"
          />
          <img
            className="gallery-img"
            src={flamingo}
            alt="flamingo balloon columns for a birthday party event"
          />
          <img
            className="gallery-img"
            src={arch16}
            alt="adorable duck balloon animal"
          />
        </div>

        <div className="gallery-row">
          <img
            className="gallery-img"
            src={garland}
            alt="balloon garland for a casino"
          />
          <img
            className="gallery-img"
            src={halloween}
            alt="halloween balloon garland "
          />
          <img
            className="gallery-img"
            src={superheroArch}
            alt="balloon garland for a 50th birthday party"
          />
        </div>

        <div className="gallery-row">
          <img
            className="gallery-img"
            src={columns}
            alt="balloon columns for a 50th birthday"
          />
          <img
            className="gallery-img"
            src={rainbowColumns}
            alt="butterfly balloon columns for an event"
          />
          <img
            className="gallery-img"
            src={mermaid}
            alt="giant balloon mermaid balloon animal"
          />
        </div>
        <div className="gallery-row">
          <img
            className="gallery-img"
            src={easter}
            alt="hyper-realistic bunny balloon animal for a holiday event"
          />
          <img
            className="gallery-img"
            src={skier}
            alt="amazing skier balloon animal for a ski event"
          />
          <img
            className="gallery-img"
            src={santa}
            alt="santa on a sleigh with rudolph balloon animal centerpiece for a holiday event"
          />
        </div>
        <div className="gallery-row">
          <img
            className="gallery-img"
            src={halloweenkev}
            alt="hyper-realistic bunny balloon animal for a holiday event"
          />
          <img
            className="gallery-img"
            src={swan}
            alt="amazing skier balloon animal for a ski event"
          />
          <img
            className="gallery-img"
            src={july4}
            alt="santa on a sleigh with rudolph balloon animal centerpiece for a holiday event"
          />
        </div>
        <hr/>
        <p className="environmental-policy">
          Environmental Policy: Kevin holds a degree in Environmental Economics
          & Policy from UC Berkeley, so we strive to make our business as
          low-impact as possbile. All our balloons are made from biodegradable
          latex, which decomposes at about the same rate as an oak leaf. We use
          minimal plastic in our designs. We never use helium (a non-renewable
          resource that comes from fossil fuel extraction) nor plastic mylar
          balloons, unless specifically requested by a client.{" "}
        </p>
      </div>
    </>
  );
};

export default Gallerypage;
