import Header from "../components/Header";
import "./pagescss/bookingpage.css";
import TwistingForm from "../components/twistingform/twistingform";
import twisting2 from "../app/assets/img/kev-twisting2.jpg";
import frog from "../app/assets/img/twisting-frogballoon.jpg";
import nana from "../app/assets/img/twisting-nana.jpg";
import owl from "../app/assets/img/kev-owl2-sm.jpg";
import babyshower from "../app/assets/img/twisting-kev-babyshower.jpg";
import dog from "../app/assets/img/twisting-dog.jpg";
import crown from "../app/assets/img/twisting-crown.jpg";
import columnimg from "../app/assets/img/column-rainbow2-square.jpg";

const Bookingpage = () => {
  return (
    <>
      <Header
        headline="Book Kevin"
        headshotDisplay="none"

        tagline="World-famous balloon twisting"
        display="none"
      />
      <div className="full-width-div">
        <div className="side-gallery side-gallery-left">
          <img
            src={frog}
            className="side-gallery-img"
            alt="Good looking New Zealand man on a beach holding a kiwi bird balloon animal"
          />
          <img
            src={crown}
            className="side-gallery-img"
            alt="beautiful girl with a balloon crown"
          />
          <img
            src={nana}
            className="side-gallery-img"
            alt="Grandma with a balloon animal"
          />
        </div>

        <div className="page-content">
          <div class="service-card">
            <div className="service-description ">
              <div className="service-card-section">
                <p className="service-description">
                  A balloon twister is a fun, interactive addition to any
                  celebration! And as the Bay Area's premier Balloon Artist,
                  Kevin puts on quite the show!{" "}
                </p>
               
              </div>
              <div className="service-card-section">
                <img
                  src={twisting2}
                  alt="Kevin, the San Francisco Bay Area's globally demanded balloon artist"
                  className="service-img service-img-float-right"
                />
                <p className="service-description">
                  With fast and dazzling twists and turns, Kevin can transform a
                  simple balloon into almost anything, from a sword to a beloved
                  character. Mesmerize your guests, and let them take a home
                  a little piece of the celebration!{" "}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="pricing-container">
              <div className="service-card pricing">
                <h4>Pricing</h4>
                <p>Minimum 1 hour, +$75 each half-hour onward</p>
                <br/>
                <div className="price-row">
                  {" "}
                  <p>1 hour:</p>
                  <p className="price"> $275</p>
                </div>

                <div className="price-row">
                  <p>1.5 hours:</p>
                  <p className="price"> $350</p>
                </div>
                <div className="price-row">
                  {" "}
                  <p>2 hours:</p>
                  <p className="price"> $425</p>
                </div>
                <div className="price-row">
                  <p>2.5 hours:</p>
                  <p className="price"> $500</p>
                </div>
                
              </div>
              <div className="service-card pricing">
                <h4>Discounts</h4>
                <p>
                  We offer discounts on Balloon Columns if you add them on to your Twisting request
                </p>
                <br/>
                <div className="pricing-container">
                  <div className="black">
                  <img
                    src={columnimg}
                    alt="balloon columns Kevin made for a 50th birthday party event"
                    className="column-img"
                  />
                    <div className="price-row">
                      {" "}
                      <p>1 Column:</p>
                      <p className="price"> $125</p>
                    </div>{" "}
                    <div className="price-row">
                      <p>2 Columns:</p>
                      <p className="price"> $200</p>
                    </div>
                    <div className="price-row">
                      <p>3 Columns:</p>
                      <p className="price"> $275</p>
                    </div>
                    <div className="price-row">
                      <p>4 Columns:</p>
                      <p className="price"> $350</p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <p className="disclaimer">
              all payments are due in cash at the end of the event
            </p>
          </div>
          <TwistingForm />
        </div>
        <div className="side-gallery">
          <img
            src={owl}
            className="side-gallery-img"
            alt="Handsome man with an otter balloon animal"
          />
          <img
            src={dog}
            className="side-gallery-img"
            alt="Kevin with his friend Taylor Rae and a balloon animal"
          />
          <img
            src={babyshower}
            className="side-gallery-img"
            alt="adorable duckling balloon animal"
          />
        </div>
      </div>
    </>
  );
};

export default Bookingpage;
